import { render, staticRenderFns } from "./StackOrderDetails_old.vue?vue&type=template&id=28ed66fd&scoped=true"
import script from "./StackOrderDetails_old.vue?vue&type=script&lang=js"
export * from "./StackOrderDetails_old.vue?vue&type=script&lang=js"
import style0 from "./StackOrderDetails_old.vue?vue&type=style&index=0&id=28ed66fd&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ed66fd",
  null
  
)

/* custom blocks */
import block0 from "./StackOrderDetails_old.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports